<template>
    <div class="task-kanban">
        <div class="header">
            <div class="name" @click="openModalTask">{{ data.name }}</div>
            <div class="user" v-if="data.user">
                <v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
                    <img class="image-avatar" :src="data.user.avatar" />
                    <template slot="popover">
                        <div class="simple-text">{{ data.user.name }}</div>
                    </template>
                </v-popover>
            </div>
        </div>
        
        <div class="content">
            <div class="task-data">
                <div class="item-data">
                    <div class="icon">
                        <icon-flag class="icon" :class="data.priority"/>
                    </div>
                </div>

                <div class="item-data">
                    <div class="icon">
                        <icon-calendar />
                    </div>
                    <div class="value" :class="{red: moment(data.deadline) < moment() && data.done === 0 }">{{ data.deadline | moment('DD/MM/YYYY') }}</div>
                </div>
            </div>

            <div class="tags" v-if="data.tags.length">
                <div class="tag" :class="{is_light: lightOrDark(tag.color_code)}" v-for="tag, index in data.tags" :style="`--color: ${tag.color_code}; --background-color: ${tag.color_code}30`">
                    <div class="text" :style="`--color: ${tag.color_code}`">{{ tag.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IconFlag from '@/components/Icons/Flag';
    import IconCalendar from '@/components/Icons/Calendar';

    export default {
		data() {
			return {
                
            };
		},
		props: {
            data: Object
        },
        components: {
            IconFlag,
            IconCalendar
        },
		mounted() {

		},
		methods: {
            lightOrDark(color) { 
				var r, g, b, hsp;

				color = +("0x" + color.slice(1).replace( color.length < 5 && /./g, '$&$&'));
				r = color >> 16;
				g = color >> 8 & 255;
				b = color & 255;

				// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
				hsp = Math.sqrt(
					0.299 * (r * r) +
					0.587 * (g * g) +
					0.114 * (b * b)
				);

				// Using the HSP value, determine whether the color is light or dark
				if (hsp>190) {
					return true;
				} else {
					return false;
				}
			},
            openModalTask() {
                var dataModal = { 
                    crud: 'edit', 
                    taskEditId: this.data.id, 
                    // type: this.data.task_log_id ? 'task_log' : 'task', 
                    date: this.data.date ? this.data.date : '',
                    from: ''
                };

                this.$root.$emit('open_modal', 'add_task', dataModal, false);
            }
		}
	};
</script>